<template>
    <a-tabs >
        <a-tab-pane key="1" tab="城市屏蔽">
                <div class="table-wrap">
                <div class="btn-wrap">
                    <div class="btn-list">
                        <a-button type="primary" @click="openAddcity"><template #icon><PlusOutlined /></template>添加城市屏蔽</a-button>
                        <a-button type="danger" @click="delMorecity"><template #icon><DeleteOutlined /></template>删除</a-button>

        

                        <a-tag v-if="system.data.is_shield_city==1" style="margin-left:20px" color="success">开启中</a-tag>
                        <a-tag v-if="system.data.is_shield_city==2" style="margin-left:20px" color="error">关闭中</a-tag>
                    </div>
                    <div>
                        <a-input-group compact>
                            <a-input v-model:value="search" placeholder="请输入城市名称"/>
                            <a-button type="primary" @click="toSearch">搜索</a-button>
                        </a-input-group>
                    </div>
                    
                </div>
                <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                    <template #bodyCell="{ column,record}">
                         <template v-if="column.dataIndex === 'create_time'">
                            <span v-if="record.create_time!=0">{{record.create_time}}</span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="delcity(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="IP屏蔽" force-render>
              <div class="table-wrap">
                <div class="btn-wrap">
                    <div class="btn-list">
                        <a-button type="primary" @click="openAddip"><template #icon><PlusOutlined /></template>添加IP屏蔽</a-button>
                        <a-button type="danger" @click="delMoreip"><template #icon><DeleteOutlined /></template>删除</a-button>

                        <a-tag v-if="system.data.is_shield_ip==1" style="margin-left:20px" color="success">开启中</a-tag>
                        <a-tag v-if="system.data.is_shield_ip==2" style="margin-left:20px" color="error">关闭中</a-tag>
                    </div>
                    <div>
                        <a-input-group compact>
                            <a-input v-model:value="search2" placeholder="请输入IP"/>
                            <a-button type="primary" @click="toSearch2">搜索</a-button>
                        </a-input-group>
                    </div>
                    
                </div>
                <a-table row-key="id" :pagination="false" :columns="columns2" :data-source="dataList2.arr"
                    :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2 }"
                >
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit2(record.id)">编辑</a-button>
                            <a-button type="link" @click="delip(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </a-tab-pane>
    </a-tabs>

    <!-- 添加、编辑城市、IP -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >

            <div style="height:420px;overflow-y:auto">
                <a-form
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 16 }"
                    autocomplete="off"
                >
                    <a-form-item label="标题" name="pid" >
                        <a-input v-model:value="formData.data.title" placeholder="标题" />
                    </a-form-item>
                    <a-form-item label="是否访问" name="pid" >
                        <a-switch :checkedValue="1" :unCheckedValue="2" checked-children="访问" un-checked-children="屏蔽" v-model:checked="formData.data.vendibility_type" />
                    </a-form-item>
                    <a-form-item label="城市" name="pid" v-if="iscity">
                        <a-button @click="addCity" type="primary" ><template #icon><PlusOutlined /></template>添加</a-button>
                        <section class="address-item" v-for="item,i in citylist.arr" :key="i">
                            <a-cascader  v-model:value="item.value" :options="options" placeholder="请选择地区" />
                            <a-button type="primary" shape="circle" danger @click="delCitylist(i)">
                                <template  #icon><minus-outlined /></template>
                            </a-button>
                        </section>
                        <!-- <a-cascader  v-model:value="value" :options="options" placeholder="请选择地区" /> -->
                    </a-form-item>

                    <a-form-item label="IP地址" name="pid" v-if="iscity==false">
                        <a-textarea v-if="ipchecked" v-model:value="formData.data.vendibility_ip" placeholder="请输入IP，一行一个" :rows="6" />
                    </a-form-item>

                </a-form>
            </div>
            
        </a-modal>
</template>

<script>
import { reactive, ref ,computed} from '@vue/reactivity';
import options from '../../common/address'
import { getCurrentInstance ,createVNode} from 'vue'
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
 import {formatDate} from '../../common/filter.js'
 import { message,Modal} from 'ant-design-vue';
const columns = [
  {title: 'ID',dataIndex: 'id'},
  {title: '标题',dataIndex: 'title'},
  {title: '屏蔽城市',dataIndex: 'vendibility_citys'},
  {title: '添加时间',dataIndex: 'create_time'},
  {title: '操作',dataIndex: 'operation',width:280},
];

const columns2 = [
  {title: 'ID',dataIndex: 'id'},
  {title: '标题',dataIndex: 'title'},
  {title: '屏蔽IP',dataIndex: 'vendibility_ip'},
  {title: '添加时间',dataIndex: 'create_time'},
  {title: '操作',dataIndex: 'operation',width:280},
];
export default {
    setup(){
        let citychecked=ref(true)
        let ipchecked=ref(true)
        let iscity=ref(true)
        let title=ref("添加城市")
        let visible=ref(false)
        const { proxy } = getCurrentInstance()

          // 过滤器
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });

        let page=reactive({page:1,pageSize:"10",total:"20"})//分页
        let page2=reactive({page:1,pageSize:"10",total:"20"})//分页

        let dataList=reactive({data:{}})  //城市
        let dataList2=reactive({data:{}})  //IP

        let formData=reactive({data:{title:"",vendibility_type:0,vendibility_provinces:"",vendibility_citys:"",vendibility_ip:""}})

          // 详情
        let system=reactive({data:{title:"",is_shield_city:"",is_shield_ip:"",log_url:""}})
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()

        // 列表
        let search=ref()
        let toSearch=function(){
            page.page=1
            getTabledata()
        }
        let search2=ref()
        let toSearch2=function(){
            page2.page=1
            getTabledata2()
        }
        let getTabledata=function(){
            proxy.$http.post('/admin/shieldcitylist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()


        let getTabledata2=function(){
            proxy.$http.post('/admin/shieldiplist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList2.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata2()

        // 城市选择
        let citylist=reactive({arr:[{value:""}]})
        let addCity=function(){
            citylist.arr.push({value:""})
        }
        let delCitylist=function(i){
            citylist.arr.splice(i,1)
        }

        // 添加
        let openAddcity=function(){
            title.value="添加城市"
            visible.value=true
            iscity.value=true
        }
        let openAddip=function(){
            title.value="添加IP"
            visible.value=true
            iscity.value=false
        }



        // 编辑
        let editid=ref()
        let openEdit=function(id){
            editid.value=id
            proxy.$http.post('/admin/shieldcitypage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    formData.data=res.data.data.data
                    if(res.data.data.data.vendibility_provinces){
                        let city=res.data.data.data.vendibility_citys
                        let province=res.data.data.data.vendibility_provinces
                        let arr1=city.split(",")
                        let arr2=province.split(",")


                        citylist.arr=[]

                        arr1 = arr2.map((key,value)=>[key,arr1[value]])
                        
                        for(var j in arr1){
                            console.log(arr1[j])
                            citylist.arr.push({value:arr1[j]})
                        }
                        console.log(citylist.arr)
                    }

                        title.value="编辑城市"
                        visible.value=true
                        iscity.value=true
                    
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let openEdit2=function(id){
            editid.value=id
            proxy.$http.post('/admin/shieldippage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    formData.data=res.data.data.data

                    title.value="编辑IP"
                    visible.value=true
                    iscity.value=false
                    
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 确定
        let handleOk=function(){
            let vendibility_provinces=""
            let vendibility_citys=""
             if(citylist.arr[0]){
                for(var i in citylist.arr){
                    vendibility_provinces=citylist.arr[i].value[0]+","+vendibility_provinces
                }
                for(var i in citylist.arr){
                    vendibility_citys=citylist.arr[i].value[1]+","+vendibility_citys
                }
                vendibility_provinces=vendibility_provinces.slice(0,-1)
                vendibility_citys=vendibility_citys.slice(0,-1)
                console.log(vendibility_provinces)
            }else{
                citylist.arr=[{value:""}]
            }
   
            if(title.value=="添加城市"){
                let vendibility_provinces=""
                let vendibility_citys=""
               
                 proxy.$http.post('/admin/addshieldcity',{title:formData.data.title,vendibility_type:formData.data.vendibility_type,
                 vendibility_provinces:vendibility_provinces,vendibility_citys:vendibility_citys
                 },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        visible.value=false
                        formData.data.title=""
                        formData.data.vendibility_type=""
                        citylist.arr=[{value:""}]
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑城市"){
                proxy.$http.post('/admin/updateshieldcity',{id:editid.value,title:formData.data.title,vendibility_type:formData.data.vendibility_type,
                 vendibility_provinces:vendibility_provinces,vendibility_citys:vendibility_citys
                 },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        visible.value=false
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="添加IP"){
                proxy.$http.post('/admin/addshieldip',{title:formData.data.title,vendibility_type:formData.data.vendibility_type,
                 vendibility_ip:formData.data.vendibility_ip
                 },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        visible.value=false
                        formData.data.title=""
                        formData.data.vendibility_type=""
                        formData.data.vendibility_ip=""
                        getTabledata2()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑IP"){
                proxy.$http.post('/admin/updateshieldip',{id:editid.value,title:formData.data.title,vendibility_type:formData.data.vendibility_type,
                 vendibility_ip:formData.data.vendibility_ip
                 },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        visible.value=false
                        getTabledata2()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }

        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

        let selectedRowKeys2=ref()
        let onSelectChange2=function(selected) {
            selectedRowKeys2.value = selected;
        }

         // 删除
        let delMorecity=function(){
            var id=selectedRowKeys.value.join(",")
            delcity(id)
        }
        let delcity=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delshieldcity',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }
        let delMoreip=function(){
            var id=selectedRowKeys2.value.join(",")
            delip(id)
        }
        let delip=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delshieldip',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata2()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }


         // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
        }

        let pageChange2=function(current, size){
            page.pageSize=size
            page.page=current
        }
        return{
            formData,handleOk,
            citylist,addCity,
            openEdit2,openEdit,delCitylist,
            iscity,title,openAddcity,openAddip,visible,
            delMorecity,delMoreip,delip,delcity,onSelectChange,onSelectChange2,
            pageChange,page,page2,pageChange2,options,getTabledata,toSearch,search,toSearch2,search2,changeFormdate,selectedRowKeys,selectedRowKeys2,
            dataList,dataList2,
            citychecked,ipchecked,
            columns,columns2,
            system
        }
    }
}
</script>