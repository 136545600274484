import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import common from './common/common.js'
import * as Icons from '@ant-design/icons-vue'
import './assets/style.css'

import { Button, Menu,Layout,Input, Dropdown, Table, Modal, Form ,ConfigProvider, PageHeader, Switch, Tabs,Descriptions, Grid, Row, Card, Avatar, List, Tag, Radio, DatePicker, Col, Cascader, Pagination, Tree, Select, Divider, Upload, Checkbox } from 'ant-design-vue'


import axios from 'axios'

const app = createApp(App)
app.use(Button)
app.use(Menu)
app.use(Layout)
app.use(Input)
app.use(Dropdown)
app.use(Table)
app.use(Modal)
app.use(Form)
app.use(ConfigProvider)
app.use(PageHeader)
app.use(Switch)
app.use(Tabs)
app.use(Descriptions)
app.use(Grid)
app.use(Row)
app.use(Col)
app.use(Card)
app.use(Avatar)
app.use(List)
app.use(Tag)
app.use(Radio)
app.use(DatePicker)
app.use(Cascader)
app.use(Pagination)
app.use(Tree)
app.use(Select)
app.use(Divider)
app.use(Upload)
app.use(Checkbox)
for (let iconName in Icons){
    app.component(iconName, Icons[iconName])
}

// 配置全局限制方法
app.config.globalProperties.$noMulClicks = common.noMulClicks

//基础配置
axios.defaults.baseURL = "https://jd.meiti360.net";
app.config.globalProperties.$http =axios


app.use(router);
app.mount('#app')

