<template>
    <div>
       
        <div class="serch-list">

        </div>
        <div class="table-wrap">
            <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="toUrl"><template #icon><PlusOutlined /></template>添加</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search" placeholder="请输入原始链接"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
                
            </div>
            <a-table class="urltd" :scroll="{ x: 1900}" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" 
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'vendibility_citys'">
                        <span v-if="record.vendibility_citys=='undefined'"></span>
                    </template>
                    <template v-if="column.dataIndex === 'is_browser'">
                        <template v-for="item,i in record.is_browser" :Key="i">
                            <p v-if="item=='1'">屏蔽pc</p>
                            <p v-if="item=='2'">屏蔽手机</p>
                            <p v-if="item=='3'">屏蔽pc虚拟浏览器</p>
                        </template>
                    </template>
                    <template v-if="column.dataIndex === 'vendibility_ips'">
                        <p v-for="item,i in record.vendibility_ips" :key="i">{{item}}</p>
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">屏蔽地址</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </div>


        <!-- 添加、编辑路径 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" style="width:580px">
            <div style="height:420px;overflow-y:auto;">
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 19 }"
                    autocomplete="off"
                >
                    <a-form-item label="解析地址" name="pid" >
                        <a-textarea :rows="4" placeholder="解析地址" readonly v-model:value="formData.data.interlinkage_create" />
                    </a-form-item>

                    <a-form-item label="失败跳转" name="telephone" >
                        <a-input placeholder="失败跳转链接" type="tel" v-model:value="formData.data.interlinkage_fail" />
                    </a-form-item>
                    <a-form-item label="屏蔽设备" name="telephone" >
                        <a-checkbox-group v-model:value="formData.data.is_browser">
                            <a-checkbox :value="1">屏蔽pc</a-checkbox>
                            <a-checkbox :value="2">屏蔽手机</a-checkbox>
                            <a-checkbox :value="3">屏蔽pc虚拟浏览器</a-checkbox>
                        </a-checkbox-group>
                    </a-form-item>
                    <a-form-item label="屏蔽城市" name="title" >
                        <a-switch v-model:checked="formData.data.vendibility_city_type" :checkedValue="1" :unCheckedValue="2" checked-children="访问" un-checked-children="屏蔽"/>
                        <!-- <v-distpicker :province="selectcity.province" :city="selectcity.city" :area="selectcity.area" ></v-distpicker> -->
                        <div style="margin-top:20px" >
                            <a-button @click="addCity" type="primary" ><template #icon><PlusOutlined /></template>添加</a-button>
                            <section class="address-item" v-for="item,i in citylist.arr" :key="i">
                                <a-cascader  v-model:value="item.value" :options="options" placeholder="请选择地区" />
                                <a-button type="primary" shape="circle" danger @click="delCity(i)">
                                    <template  #icon><minus-outlined /></template>
                                </a-button>
                            </section>
                            
                        </div>
                        
                    </a-form-item>
                    <a-form-item label="屏蔽IP" name="href" >
                        <a-switch v-model:checked="formData.data.vendibility_ip_type" :checkedValue="1" :unCheckedValue="2" checked-children="访问" un-checked-children="屏蔽"/>
                        <a-textarea style="margin-top:20px"  v-model:value="formData.data.vendibility_ips" placeholder="请输入IP，一行一个" :rows="6" />
                    </a-form-item>
                </a-form>
            </div>
            
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import options from '../../common/address'
import { getCurrentInstance ,createVNode} from 'vue'
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
 import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '用户地址',dataIndex: 'interlinkage',width:240},
    {title: '解析地址',dataIndex: 'interlinkage_create',width:520},
    {title: '屏蔽城市',dataIndex: 'vendibility_citys',width:210},
    {title: '屏蔽IP',dataIndex: 'vendibility_ips',width:130},
    {title: '屏蔽设备',dataIndex: 'is_browser',width:150},
    {title: '失败跳转',dataIndex: 'interlinkage_fail',width:230},
    {title: '添加时间',dataIndex: 'create_time',width:180},
    {title: '操作',dataIndex: 'operation',width:180, fixed: 'right'}
];
export default {
    setup(){
        
        // let selectcity = reactive({ province: '', city: '', area: '' })
        let search=ref()
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页

        let dataList=reactive({arr:[{}]})
        let formData=reactive({data:{vendibility_city_type:"",vendibility_provinces:"",vendibility_citys:"",vendibility_ip_type:"",vendibility_ips:"",remarks:"",
        interlinkage_fail:"",is_browser:""
        }})
        const { proxy } = getCurrentInstance()

         // 列表
        let toSearch=function(){
            page.page=1
            getTabledata()
        }
        let toUrl=function(){
            window.open("http://index.meiti360.net/", '_blank') 
        }
        let getTabledata=function(){
            proxy.$http.post('/admin/interlinkagelist',{page:page.page,pagesize:page.pageSize,interlinkage:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

              // 城市选择
        let citylist=reactive({arr:[{value:""}]})
        let addCity=function(){
            citylist.arr.push({value:""})
        }
        let delCity=function(i){
            citylist.arr.splice(i,1)
        }
  

        let visible=ref(false)
        let title=ref("编辑")

        let ipchecked=ref(false)
        let citychecked=ref(false)


        // 添加、编辑
        let openAdd=function(){

            visible.value=true
        }
        let editid=ref()
        let openEdit=function(id){
            editid.value=id
            proxy.$http.post('/admin/interlinkagepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    formData.data=res.data.data.data
                    if(res.data.data.data.vendibility_provinces&&res.data.data.data.vendibility_provinces!="undefined"){
                        let city=res.data.data.data.vendibility_citys
                        let province=res.data.data.data.vendibility_provinces
                        let arr1=city.split(",")
                        let arr2=province.split(",")
                   

                        citylist.arr=[]

                        arr1 = arr2.map((key,value)=>[key,arr1[value]])
                        
                        for(var j in arr1){
                            console.log(arr1[j])
                            citylist.arr.push({value:arr1[j]})
                        }
                        // console.log(citylist.arr)
                    }else{
                        citylist.arr=[{value:""}]
                    }

                     if(res.data.data.data.is_browser[0]){
                        var arr=[]
                        for(var i in res.data.data.data.is_browser){
                            arr.push(parseInt(res.data.data.data.is_browser[i]) )
                        }
                        formData.data.is_browser=arr
                    }
                    title.value="编辑路径"
                    visible.value=true
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let handleOk=function(){
            let vendibility_provinces=""
            let vendibility_citys=""
             if(citylist.arr[0]){
                for(var i in citylist.arr){
                    vendibility_provinces=citylist.arr[i].value[0]+","+vendibility_provinces
                }
                for(var i in citylist.arr){
                    vendibility_citys=citylist.arr[i].value[1]+","+vendibility_citys
                }
                vendibility_provinces=vendibility_provinces.slice(0,-1)
                vendibility_citys=vendibility_citys.slice(0,-1)
                console.log(vendibility_provinces)
            }else{
                vendibility_provinces=""
                vendibility_citys=""
            }

            var is_browser=""
            if(formData.data.is_browser[0]){
                is_browser=formData.data.is_browser.join(",")
            }
            proxy.$http.post('/admin/updateinterlinkage',{id:editid.value,vendibility_city_type:formData.data.vendibility_city_type,vendibility_provinces:vendibility_provinces,
                vendibility_citys:vendibility_citys,vendibility_ip_type:formData.data.vendibility_ip_type,vendibility_ips:formData.data.vendibility_ips,is_browser:is_browser,
                interlinkage_fail:formData.data.interlinkage_fail
                },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg)
                    visible.value=false
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

         // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delinterlinkage',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
        }

        return{
            toSearch,toUrl,
            selectedRowKeys,onSelectChange,delMore,del,
            openAdd,options,citylist,addCity,delCity,openEdit,
            ipchecked,citychecked,
            visible,formData,handleOk,title,
            search,dataList,columns,
            pageChange,page
        }
    }
}
</script>