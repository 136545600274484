<template>
    <div>
        <a-tabs  animated :size="size">
            <a-tab-pane key="1" tab="网站信息" class="person-wrap">
                <!-- <h2 class="person-title">基本信息</h2> -->
                <a-form
                    :model="system.data"
                    name="basic"
                    :label-col="{ span: 3 }"
                    :wrapper-col="{ span: 8 }"
                    autocomplete="off"
                    style="width:80%"
                >
                    <a-form-item label="网站名称" >
                        <a-input v-model:value="system.data.title" placeholder="网站名称"/>
                    </a-form-item>

                    <a-form-item label="Logo" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader logoUpload"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://jd.meiti360.net/admin/addlogfile"
                            @change="({ file }) => handleChangeImg(file)"
                        >
                            <img v-if="system.data.log_url" :src="'https://jd.meiti360.net/'+system.data.log_url" style="width:168px;height:32px" alt="avatar" />
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">图片上传</div>
                            </div>
                            
                        </a-upload>
                        <span>（图片大小为168*32）</span>
                    </a-form-item>
                    <a-form-item label="失败跳转" name="telephone" >
                        <a-input placeholder="失败跳转链接" type="tel" v-model:value="system.data.interlinkage_fail" />
                    </a-form-item>
                    <a-form-item label="屏蔽设备" name="telephone" >
                        <a-checkbox-group v-model:value="system.data.is_browser">
                            <!-- <a-checkbox :value="0">不屏蔽</a-checkbox> -->
                            <a-checkbox :value="1">屏蔽pc</a-checkbox>
                            <a-checkbox :value="2">屏蔽手机</a-checkbox>
                            <a-checkbox :value="3">屏蔽pc和手机</a-checkbox>
                        </a-checkbox-group>
                    </a-form-item>

                    <a-form-item label="是否开启城市屏蔽" >
                        <a-switch v-model:checked="system.data.is_shield_city" :checkedValue="1" :unCheckedValue="2" checked-children="开启" un-checked-children="关闭"/>
                    </a-form-item>
                    <a-form-item label="是否开启IP屏蔽" >
                         <a-switch v-model:checked="system.data.is_shield_ip" :checkedValue="1" :unCheckedValue="2" checked-children="开启" un-checked-children="关闭"/>
                    </a-form-item>

                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }">
                        <a-button @click="toSubmit" type="primary" >修改配置</a-button>
                    </a-form-item>
                </a-form>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { getCurrentInstance , reactive,ref} from 'vue'
import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let system=reactive({data:{title:"",is_shield_city:"",is_shield_ip:"",log_url:"",is_browser:"",interlinkage_fail:""}})

         // 详情
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data=res.data.data.data

                    if(res.data.data.data.is_browser[0]){
                        var arr=[]
                        for(var i in res.data.data.data.is_browser){
                            arr.push(parseInt(res.data.data.data.is_browser[i]) )
                        }
                        system.data.is_browser=arr
                    }
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()



        // 上传logo
        let loading=ref(false)
        let headers=reactive({'token':sessionStorage.getItem("token")})
         let handleChangeImg=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                system.data.log_url=info.response.data.file_src
            }
        }

        // 修改配置
        let toSubmit=function(){
            var is_browser=""
            if(system.data.is_browser[0]){
                is_browser=system.data.is_browser.join(",")
            }
            proxy.$http.post('/admin/updatesystemset',{id:1,title:system.data.title,is_shield_city:system.data.is_shield_city,
            is_shield_ip:system.data.is_shield_ip,log_url:system.data.log_url,interlinkage_fail:system.data.interlinkage_fail,is_browser:is_browser},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }


        return{
            system,toSubmit,
            handleChangeImg,loading,headers
        }
    }
}
</script>