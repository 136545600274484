const options = [
    {
        "code": "110000",
        "value": "北京",
        "label": "北京",
        "children": [
            {
                "code": "110101",
                "value": "北京",
                "label": "北京"
            }
        ]
    },
    {
        "code": "120000",
        "value": "天津",
        "label": "天津",
        "children": [
            {
                "code": "120101",
                "value": "天津",
                "label": "天津"
            }
        ]
    },
    {
        "code": "130000",
        "value": "河北",
        "label": "河北",
        "children": [
            {
                "code": "130100",
                "value": "石家庄",
                "label": "石家庄",

            },
            {
                "code": "130200",
                "value": "唐山",
                "label": "唐山",
            },
            {
                "code": "130300",
                "value": "秦皇岛",
                "label": "秦皇岛",
            },
            {
                "code": "130400",
                "value": "邯郸",
                "label": "邯郸",
            },
            {
                "code": "130500",
                "value": "邢台",
                "label": "邢台",
            },
            {
                "code": "130600",
                "value": "保定",
                "label": "保定",
            },
            {
                "code": "130700",
                "value": "张家口",
                "label": "张家口",
            },
            {
                "code": "130800",
                "value": "承德",
                "label": "承德",
            },
            {
                "code": "130900",
                "value": "沧州",
                "label": "沧州",
            },
            {
                "code": "131000",
                "value": "廊坊",
                "label": "廊坊",
            },
            {
                "code": "131100",
                "value": "衡水",
                "label": "衡水",

            }
        ]
    },
    {
        "code": "140000",
        "value": "山西",
        "label": "山西",
        "children": [
            {
                "code": "140100",
                "value": "太原",
                "label": "太原",

            },
            {
                "code": "140200",
                "value": "大同",
                "label": "大同",

            },
            {
                "code": "140300",
                "value": "阳泉",
                "label": "阳泉",
            },
            {
                "code": "140400",
                "value": "长治",
                "label": "长治",
            },
            {
                "code": "140500",
                "value": "晋城",
                "label": "晋城",
            },
            {
                "code": "140600",
                "value": "朔州",
                "label": "朔州",
            },
            {
                "code": "140700",
                "value": "晋中",
                "label": "晋中",

            },
            {
                "code": "140800",
                "value": "运城",
                "label": "运城",

            },
            {
                "code": "140900",
                "value": "忻州",
                "label": "忻州",
            },
            {
                "code": "141000",
                "value": "临汾",
                "label": "临汾",
            },
            {
                "code": "141100",
                "value": "吕梁",
                "label": "吕梁",
            }
        ]
    },
    {
        "code": "150000",
        "value": "内蒙古自治区",
        "label": "内蒙古自治区",
        "children": [
            {
                "code": "150100",
                "value": "呼和浩特",
                "label": "呼和浩特",
            },
            {
                "code": "150200",
                "value": "包头",
                "label": "包头",
            },
            {
                "code": "150300",
                "value": "乌海",
                "label": "乌海",
            },
            {
                "code": "150400",
                "value": "赤峰",
                "label": "赤峰",
            },
            {
                "code": "150500",
                "value": "通辽",
                "label": "通辽",
            },
            {
                "code": "150600",
                "value": "鄂尔多斯",
                "label": "鄂尔多斯",
            },
            {
                "code": "150700",
                "value": "呼伦贝尔",
                "label": "呼伦贝尔",
            },
            {
                "code": "150800",
                "value": "巴彦淖尔",
                "label": "巴彦淖尔",
            },
            {
                "code": "150900",
                "value": "乌兰察布",
                "label": "乌兰察布",
            },
            {
                "code": "152200",
                "value": "兴安盟",
                "label": "兴安盟",
            },
            {
                "code": "152500",
                "value": "锡林郭勒盟",
                "label": "锡林郭勒盟",

            },
            {
                "code": "152900",
                "value": "阿拉善盟",
                "label": "阿拉善盟",

            }
        ]
    },
    {
        "code": "210000",
        "value": "辽宁",
        "label": "辽宁",
        "children": [
            {
                "code": "210100",
                "value": "沈阳",
                "label": "沈阳",
    
            },
            {
                "code": "210200",
                "value": "大连",
                "label": "大连",
      
            },
            {
                "code": "210300",
                "value": "鞍山",
                "label": "鞍山",
           
            },
            {
                "code": "210400",
                "value": "抚顺",
                "label": "抚顺",
     
            },
            {
                "code": "210500",
                "value": "本溪",
                "label": "本溪",
         
            },
            {
                "code": "210600",
                "value": "丹东",
                "label": "丹东",
         
            },
            {
                "code": "210700",
                "value": "锦州",
                "label": "锦州",
         
            },
            {
                "code": "210800",
                "value": "营口",
                "label": "营口",
         
            },
            {
                "code": "210900",
                "value": "阜新",
                "label": "阜新",
            
            },
            {
                "code": "211000",
                "value": "辽阳",
                "label": "辽阳",
       
            },
            {
                "code": "211100",
                "value": "盘锦",
                "label": "盘锦",
    
            },
            {
                "code": "211200",
                "value": "铁岭",
                "label": "铁岭",
          
            },
            {
                "code": "211300",
                "value": "朝阳",
                "label": "朝阳",
             
            },
            {
                "code": "211400",
                "value": "葫芦岛",
                "label": "葫芦岛",
          
            }
        ]
    },
    {
        "code": "220000",
        "value": "吉林",
        "label": "吉林",
        "children": [
            {
                "code": "220100",
                "value": "长春",
                "label": "长春",
        
            },
            {
                "code": "220300",
                "value": "四平",
                "label": "四平",
          
            },
            {
                "code": "220400",
                "value": "辽源",
                "label": "辽源",
           
            },
            {
                "code": "220500",
                "value": "通化",
                "label": "通化",

            },
            {
                "code": "220600",
                "value": "白山",
                "label": "白山",
      
            },
            {
                "code": "220700",
                "value": "松原",
                "label": "松原",
       
            },
            {
                "code": "220800",
                "value": "白城",
                "label": "白城",
     
            },
            {
                "code": "222400",
                "value": "延边朝鲜族自治州",
                "label": "延边朝鲜族自治州",
     
            }
        ]
    },
    {
        "code": "230000",
        "value": "黑龙江",
        "label": "黑龙江",
        "children": [
            {
                "code": "230100",
                "value": "哈尔滨",
                "label": "哈尔滨",
      
            },
            {
                "code": "230200",
                "value": "齐齐哈尔",
                "label": "齐齐哈尔",
    
            },
            {
                "code": "230300",
                "value": "鸡西",
                "label": "鸡西",
    
            },
            {
                "code": "230400",
                "value": "鹤岗",
                "label": "鹤岗",
       
            },
            {
                "code": "230500",
                "value": "双鸭山",
                "label": "双鸭山",
          
            },
            {
                "code": "230600",
                "value": "大庆",
                "label": "大庆",
         
            },
            {
                "code": "230700",
                "value": "伊春",
                "label": "伊春",
         
            },
            {
                "code": "230800",
                "value": "佳木斯",
                "label": "佳木斯",
          
            },
            {
                "code": "230900",
                "value": "七台河",
                "label": "七台河",
           
            },
            {
                "code": "231000",
                "value": "牡丹江",
                "label": "牡丹江",
         
            },
            {
                "code": "231100",
                "value": "黑河",
                "label": "黑河",
       
            },
            {
                "code": "231200",
                "value": "绥化",
                "label": "绥化",
         
            },
            {
                "code": "232700",
                "value": "大兴安岭地区",
                "label": "大兴安岭地区",
         
            }
        ]
    },
    {
        "code": "310000",
        "value": "上海",
        "label": "上海",
        "children": [
            {
                "code": "310101",
                "value": "上海",
                "label": "上海"
            }
           
        ]
    },
    {
        "code": "320000",
        "value": "江苏",
        "label": "江苏",
        "children": [
            {
                "code": "320100",
                "value": "南京",
                "label": "南京",
         
            },
            {
                "code": "320200",
                "value": "无锡",
                "label": "无锡",
            
            },
            {
                "code": "320300",
                "value": "徐州",
                "label": "徐州",
         
            },
            {
                "code": "320400",
                "value": "常州",
                "label": "常州",
         
            },
            {
                "code": "320500",
                "value": "苏州",
                "label": "苏州",
        
            },
            {
                "code": "320600",
                "value": "南通",
                "label": "南通",
       
            },
            {
                "code": "320700",
                "value": "连云港",
                "label": "连云港",
           
            },
            {
                "code": "320800",
                "value": "淮安",
                "label": "淮安",
          
            },
            {
                "code": "320900",
                "value": "盐城",
                "label": "盐城",
         
            },
            {
                "code": "321000",
                "value": "扬州",
                "label": "扬州",
    
            },
            {
                "code": "321100",
                "value": "镇江",
                "label": "镇江",
       
            },
            {
                "code": "321200",
                "value": "泰州",
                "label": "泰州",
           
            },
            {
                "code": "321300",
                "value": "宿迁",
                "label": "宿迁",
            
            }
        ]
    },
    {
        "code": "330000",
        "value": "浙江",
        "label": "浙江",
        "children": [
            {
                "code": "330100",
                "value": "杭州",
                "label": "杭州",
            
            },
            {
                "code": "330200",
                "value": "宁波",
                "label": "宁波",
          
            },
            {
                "code": "330300",
                "value": "温州",
                "label": "温州",
           
            },
            {
                "code": "330400",
                "value": "嘉兴",
                "label": "嘉兴",
            
            },
            {
                "code": "330500",
                "value": "湖州",
                "label": "湖州",
           
            },
            {
                "code": "330600",
                "value": "绍兴",
                "label": "绍兴",
           
            },
            {
                "code": "330700",
                "value": "金华",
                "label": "金华",
       
            },
            {
                "code": "330800",
                "value": "衢州",
                "label": "衢州",
         
            },
            {
                "code": "330900",
                "value": "舟山",
                "label": "舟山",
        
            },
            {
                "code": "331000",
                "value": "台州",
                "label": "台州",
        
            },
            {
                "code": "331100",
                "value": "丽水",
                "label": "丽水",
              
            }
        ]
    },
    {
        "code": "340000",
        "value": "安徽",
        "label": "安徽",
        "children": [
            {
                "code": "340100",
                "value": "合肥",
                "label": "合肥",
       
            },
            {
                "code": "340200",
                "value": "芜湖",
                "label": "芜湖",
        
            },
            {
                "code": "340300",
                "value": "蚌埠",
                "label": "蚌埠",
           
            },
            {
                "code": "340400",
                "value": "淮南",
                "label": "淮南",
           
            },
            {
                "code": "340500",
                "value": "马鞍山",
                "label": "马鞍山",
            
            },
            {
                "code": "340600",
                "value": "淮北",
                "label": "淮北",
             
            },
            {
                "code": "340700",
                "value": "铜陵",
                "label": "铜陵",
       
            },
            {
                "code": "340800",
                "value": "安庆",
                "label": "安庆",
         
            },
            {
                "code": "341000",
                "value": "黄山",
                "label": "黄山",
          
            },
            {
                "code": "341100",
                "value": "滁州",
                "label": "滁州",
           
            },
            {
                "code": "341200",
                "value": "阜阳",
                "label": "阜阳",
           
            },
            {
                "code": "341300",
                "value": "宿州",
                "label": "宿州",
           
            },
            {
                "code": "341500",
                "value": "六安",
                "label": "六安",
          
            },
            {
                "code": "341600",
                "value": "亳州",
                "label": "亳州",
   
            },
            {
                "code": "341700",
                "value": "池州",
                "label": "池州",
           
            },
            {
                "code": "341800",
                "value": "宣城",
                "label": "宣城",
           
            }
        ]
    },
    {
        "code": "350000",
        "value": "福建",
        "label": "福建",
        "children": [
            {
                "code": "350100",
                "value": "福州",
                "label": "福州",
       
            },
            {
                "code": "350200",
                "value": "厦门",
                "label": "厦门",
          
            },
            {
                "code": "350300",
                "value": "莆田",
                "label": "莆田",
      
            },
            {
                "code": "350400",
                "value": "三明",
                "label": "三明",
          
            },
            {
                "code": "350500",
                "value": "泉州",
                "label": "泉州",
       
            },
            {
                "code": "350600",
                "value": "漳州",
                "label": "漳州",
         
            },
            {
                "code": "350700",
                "value": "南平",
                "label": "南平",
           
            },
            {
                "code": "350800",
                "value": "龙岩",
                "label": "龙岩",
          
            },
            {
                "code": "350900",
                "value": "宁德",
                "label": "宁德",
          
            }
        ]
    },
    {
        "code": "360000",
        "value": "江西",
        "label": "江西",
        "children": [
            {
                "code": "360100",
                "value": "南昌",
                "label": "南昌",
          
            },
            {
                "code": "360200",
                "value": "景德镇",
                "label": "景德镇",
            
            },
            {
                "code": "360300",
                "value": "萍乡",
                "label": "萍乡",
         
            },
            {
                "code": "360400",
                "value": "九江",
                "label": "九江",
            
            },
            {
                "code": "360500",
                "value": "新余",
                "label": "新余",
         
            },
            {
                "code": "360600",
                "value": "鹰潭",
                "label": "鹰潭",
            
            },
            {
                "code": "360700",
                "value": "赣州",
                "label": "赣州",
         
            },
            {
                "code": "360800",
                "value": "吉安",
                "label": "吉安",
       
            },
            {
                "code": "360900",
                "value": "宜春",
                "label": "宜春",
         
            },
            {
                "code": "361000",
                "value": "抚州",
                "label": "抚州",
       
            },
            {
                "code": "361100",
                "value": "上饶",
                "label": "上饶",
           
            }
        ]
    },
    {
        "code": "370000",
        "value": "山东",
        "label": "山东",
        "children": [
            {
                "code": "370100",
                "value": "济南",
                "label": "济南",

            },
            {
                "code": "370200",
                "value": "青岛",
                "label": "青岛",
   
            },
            {
                "code": "370300",
                "value": "淄博",
                "label": "淄博",
   
            },
            {
                "code": "370400",
                "value": "枣庄",
                "label": "枣庄",
       
            },
            {
                "code": "370500",
                "value": "东营",
                "label": "东营",

            },
            {
                "code": "370600",
                "value": "烟台",
                "label": "烟台",

            },
            {
                "code": "370700",
                "value": "潍坊",
                "label": "潍坊",
  
            },
            {
                "code": "370800",
                "value": "济宁",
                "label": "济宁",

            },
            {
                "code": "370900",
                "value": "泰安",
                "label": "泰安",

            },
            {
                "code": "371000",
                "value": "威海",
                "label": "威海",

            },
            {
                "code": "371100",
                "value": "日照",
                "label": "日照",
      
            },
            {
                "code": "371300",
                "value": "临沂",
                "label": "临沂",

            },
            {
                "code": "371400",
                "value": "德州",
                "label": "德州",

            },
            {
                "code": "371500",
                "value": "聊城",
                "label": "聊城",
         
            },
            {
                "code": "371600",
                "value": "滨州",
                "label": "滨州",
   
            },
            {
                "code": "371700",
                "value": "菏泽",
                "label": "菏泽",
 
            }
        ]
    },
    {
        "code": "410000",
        "value": "河南",
        "label": "河南",
        "children": [
            {
                "code": "410100",
                "value": "郑州",
                "label": "郑州",

            },
            {
                "code": "410200",
                "value": "开封",
                "label": "开封",

            },
            {
                "code": "410300",
                "value": "洛阳",
                "label": "洛阳",

            },
            {
                "code": "410400",
                "value": "平顶山",
                "label": "平顶山",
      
            },
            {
                "code": "410500",
                "value": "安阳",
                "label": "安阳",

            },
            {
                "code": "410600",
                "value": "鹤壁",
                "label": "鹤壁",
 
            },
            {
                "code": "410700",
                "value": "新乡",
                "label": "新乡",
        
            },
            {
                "code": "410800",
                "value": "焦作",
                "label": "焦作",
 
            },
            {
                "code": "410900",
                "value": "濮阳",
                "label": "濮阳",
   
            },
            {
                "code": "411000",
                "value": "许昌",
                "label": "许昌",
      
            },
            {
                "code": "411100",
                "value": "漯河",
                "label": "漯河",
    
            },
            {
                "code": "411200",
                "value": "三门峡",
                "label": "三门峡",
       
            },
            {
                "code": "411300",
                "value": "南阳",
                "label": "南阳",
         
            },
            {
                "code": "411400",
                "value": "商丘",
                "label": "商丘",
      
            },
            {
                "code": "411500",
                "value": "信阳",
                "label": "信阳",
    
            },
            {
                "code": "411600",
                "value": "周口",
                "label": "周口",
      
            },
            {
                "code": "411700",
                "value": "驻马店",
                "label": "驻马店",

            },
            {
                "code": "419001",
                "value": "济源",
                "label": "济源",
            }
        ]
    },
    {
        "code": "420000",
        "value": "湖北",
        "label": "湖北",
        "children": [
            {
                "code": "420100",
                "value": "武汉",
                "label": "武汉",
    
            },
            {
                "code": "420200",
                "value": "黄石",
                "label": "黄石",
 
            },
            {
                "code": "420300",
                "value": "十堰",
                "label": "十堰",
    
            },
            {
                "code": "420500",
                "value": "宜昌",
                "label": "宜昌",
   
            },
            {
                "code": "420600",
                "value": "襄阳",
                "label": "襄阳",
  
            },
            {
                "code": "420700",
                "value": "鄂州",
                "label": "鄂州",

            },
            {
                "code": "420800",
                "value": "荆门",
                "label": "荆门",

            },
            {
                "code": "420900",
                "value": "孝感",
                "label": "孝感",

            },
            {
                "code": "421000",
                "value": "荆州",
                "label": "荆州",

            },
            {
                "code": "421100",
                "value": "黄冈",
                "label": "黄冈",

            },
            {
                "code": "421200",
                "value": "咸宁",
                "label": "咸宁",

            },
            {
                "code": "421300",
                "value": "随州",
                "label": "随州",

            },
            {
                "code": "422800",
                "value": "恩施土家族苗族自治州",
                "label": "恩施土家族苗族自治州",
  
            },
            {
                "code": "429004",
                "value": "仙桃",
                "label": "仙桃",
            },
            {
                "code": "429005",
                "value": "潜江",
                "label": "潜江",
            },
            {
                "code": "429006",
                "value": "天门",
                "label": "天门",
            },
            {
                "code": "429021",
                "value": "神农架林区",
                "label": "神农架林区",
            }
        ]
    },
    {
        "code": "430000",
        "value": "湖南",
        "label": "湖南",
        "children": [
            {
                "code": "430100",
                "value": "长沙",
                "label": "长沙",
            },
            {
                "code": "430200",
                "value": "株洲",
                "label": "株洲",
            },
            {
                "code": "430300",
                "value": "湘潭",
                "label": "湘潭",
            },
            {
                "code": "430400",
                "value": "衡阳",
                "label": "衡阳",
            },
            {
                "code": "430500",
                "value": "邵阳",
                "label": "邵阳",
            },
            {
                "code": "430600",
                "value": "岳阳",
                "label": "岳阳",
            },
            {
                "code": "430700",
                "value": "常德",
                "label": "常德",
            },
            {
                "code": "430800",
                "value": "张家界",
                "label": "张家界",
            },
            {
                "code": "430900",
                "value": "益阳",
                "label": "益阳",

            },
            {
                "code": "431000",
                "value": "郴州",
                "label": "郴州",
            },
            {
                "code": "431100",
                "value": "永州",
                "label": "永州",
            },
            {
                "code": "431200",
                "value": "怀化",
                "label": "怀化",
            },
            {
                "code": "431300",
                "value": "娄底",
                "label": "娄底",
            },
            {
                "code": "433100",
                "value": "湘西土家族苗族自治州",
                "label": "湘西土家族苗族自治州",
            }
        ]
    },
    {
        "code": "440000",
        "value": "广东",
        "label": "广东",
        "children": [
            {
                "code": "440100",
                "value": "广州",
                "label": "广州",
            },
            {
                "code": "440200",
                "value": "韶关",
                "label": "韶关",
            },
            {
                "code": "440300",
                "value": "深圳",
                "label": "深圳",
            },
            {
                "code": "440400",
                "value": "珠海",
                "label": "珠海",
            },
            {
                "code": "440500",
                "value": "汕头",
                "label": "汕头",
    
            },
            {
                "code": "440600",
                "value": "佛山",
                "label": "佛山",
            },
            {
                "code": "440700",
                "value": "江门",
                "label": "江门",
            },
            {
                "code": "440800",
                "value": "湛江",
                "label": "湛江",
            },
            {
                "code": "440900",
                "value": "茂名",
                "label": "茂名",
            },
            {
                "code": "441200",
                "value": "肇庆",
                "label": "肇庆",

            },
            {
                "code": "441300",
                "value": "惠州",
                "label": "惠州",

            },
            {
                "code": "441400",
                "value": "梅州",
                "label": "梅州",

            },
            {
                "code": "441500",
                "value": "汕尾",
                "label": "汕尾",
    
            },
            {
                "code": "441600",
                "value": "河源",
                "label": "河源",

            },
            {
                "code": "441700",
                "value": "阳江",
                "label": "阳江",

            },
            {
                "code": "441800",
                "value": "清远",
                "label": "清远",

            },
            {
                "code": "441900",
                "value": "东莞",
                "label": "东莞",

            },
            {
                "code": "442000",
                "value": "中山",
                "label": "中山",

            },
            {
                "code": "445100",
                "value": "潮州",
                "label": "潮州",

            },
            {
                "code": "445200",
                "value": "揭阳",
                "label": "揭阳",

            },
            {
                "code": "445300",
                "value": "云浮",
                "label": "云浮",
            }
        ]
    },
    {
        "code": "450000",
        "value": "广西",
        "label": "广西",
        "children": [
            {
                "code": "450100",
                "value": "南宁",
                "label": "南宁",
            },
            {
                "code": "450200",
                "value": "柳州",
                "label": "柳州",
    
            },
            {
                "code": "450300",
                "value": "桂林",
                "label": "桂林",
           
            },
            {
                "code": "450400",
                "value": "梧州",
                "label": "梧州",
    
            },
            {
                "code": "450500",
                "value": "北海",
                "label": "北海",
   
            },
            {
                "code": "450600",
                "value": "防城港",
                "label": "防城港",
     
            },
            {
                "code": "450700",
                "value": "钦州",
                "label": "钦州",
    
            },
            {
                "code": "450800",
                "value": "贵港",
                "label": "贵港",
     
            },
            {
                "code": "450900",
                "value": "玉林",
                "label": "玉林",
 
            },
            {
                "code": "451000",
                "value": "百色",
                "label": "百色",
     
            },
            {
                "code": "451100",
                "value": "贺州",
                "label": "贺州",
       
            },
            {
                "code": "451200",
                "value": "河池",
                "label": "河池",
    
            },
            {
                "code": "451300",
                "value": "来宾",
                "label": "来宾",
     
            },
            {
                "code": "451400",
                "value": "崇左",
                "label": "崇左",
    
            }
        ]
    },
    {
        "code": "460000",
        "value": "海南",
        "label": "海南",
        "children": [
            {
                "code": "460100",
                "value": "海口",
                "label": "海口",
          
            },
            {
                "code": "460200",
                "value": "三亚",
                "label": "三亚",

            },
            {
                "code": "460300",
                "value": "三沙",
                "label": "三沙",

            },
            {
                "code": "460400",
                "value": "儋州",
                "label": "儋州",

            },
            {
                "code": "469001",
                "value": "五指山",
                "label": "五指山",
 
            },
            {
                "code": "469002",
                "value": "琼海",
                "label": "琼海",

            },
            {
                "code": "469005",
                "value": "文昌",
                "label": "文昌",
 
            },
            {
                "code": "469006",
                "value": "万宁",
                "label": "万宁",
      
            },
            {
                "code": "469007",
                "value": "东方",
                "label": "东方",
  
            },
            {
                "code": "469021",
                "value": "定安县",
                "label": "定安县",

            },
            {
                "code": "469022",
                "value": "屯昌县",
                "label": "屯昌县",
  
            },
            {
                "code": "469023",
                "value": "澄迈县",
                "label": "澄迈县",

            },
            {
                "code": "469024",
                "value": "临高县",
                "label": "临高县",

            },
            {
                "code": "469025",
                "value": "白沙黎族自治县",
                "label": "白沙黎族自治县",
                "children": []
            },
            {
                "code": "469026",
                "value": "昌江黎族自治县",
                "label": "昌江黎族自治县",

            },
            {
                "code": "469027",
                "value": "乐东黎族自治县",
                "label": "乐东黎族自治县",
        
            },
            {
                "code": "469028",
                "value": "陵水黎族自治县",
                "label": "陵水黎族自治县",
     
            },
            {
                "code": "469029",
                "value": "保亭黎族苗族自治县",
                "label": "保亭黎族苗族自治县",

            },
            {
                "code": "469030",
                "value": "琼中黎族苗族自治县",
                "label": "琼中黎族苗族自治县",
   
            }
        ]
    },
    {
        "code": "500000",
        "value": "重庆",
        "label": "重庆",
        "children": [
            {
                "code": "500101",
                "value": "重庆",
                "label": "重庆"
            }
        ]
    },
    {
        "code": "510000",
        "value": "四川",
        "label": "四川",
        "children": [
            {
                "code": "510100",
                "value": "成都",
                "label": "成都",
       
            },
            {
                "code": "510300",
                "value": "自贡",
                "label": "自贡",
     
            },
            {
                "code": "510400",
                "value": "攀枝花",
                "label": "攀枝花",
      
            },
            {
                "code": "510500",
                "value": "泸州",
                "label": "泸州",
    
            },
            {
                "code": "510600",
                "value": "德阳",
                "label": "德阳",
    
            },
            {
                "code": "510700",
                "value": "绵阳",
                "label": "绵阳",
   
            },
            {
                "code": "510800",
                "value": "广元",
                "label": "广元",
    
            },
            {
                "code": "510900",
                "value": "遂宁",
                "label": "遂宁",
 
            },
            {
                "code": "511000",
                "value": "内江",
                "label": "内江",
    
            },
            {
                "code": "511100",
                "value": "乐山",
                "label": "乐山",

            },
            {
                "code": "511300",
                "value": "南充",
                "label": "南充",
      
            },
            {
                "code": "511400",
                "value": "眉山",
                "label": "眉山",
  
            },
            {
                "code": "511500",
                "value": "宜宾",
                "label": "宜宾",
   
            },
            {
                "code": "511600",
                "value": "广安",
                "label": "广安",
 
            },
            {
                "code": "511700",
                "value": "达州",
                "label": "达州",
   
            },
            {
                "code": "511800",
                "value": "雅安",
                "label": "雅安",
  
            },
            {
                "code": "511900",
                "value": "巴中",
                "label": "巴中",

            },
            {
                "code": "512000",
                "value": "资阳",
                "label": "资阳",

            },
            {
                "code": "513200",
                "value": "阿坝藏族羌族自治州",
                "label": "阿坝藏族羌族自治州",
  
            },
            {
                "code": "513300",
                "value": "甘孜藏族自治州",
                "label": "甘孜藏族自治州",
       
            },
            {
                "code": "513400",
                "value": "凉山彝族自治州",
                "label": "凉山彝族自治州",
    
            }
        ]
    },
    {
        "code": "520000",
        "value": "贵州",
        "label": "贵州",
        "children": [
            {
                "code": "520100",
                "value": "贵阳",
                "label": "贵阳",
            },
            {
                "code": "520200",
                "value": "六盘水",
                "label": "六盘水",
 
            },
            {
                "code": "520300",
                "value": "遵义",
                "label": "遵义",
  
            },
            {
                "code": "520400",
                "value": "安顺",
                "label": "安顺",
    
            },
            {
                "code": "520500",
                "value": "毕节",
                "label": "毕节",

            },
            {
                "code": "520600",
                "value": "铜仁",
                "label": "铜仁",
 
            },
            {
                "code": "522300",
                "value": "黔西南布依族苗族自治州",
                "label": "黔西南布依族苗族自治州",
       
            },
            {
                "code": "522600",
                "value": "黔东南苗族侗族自治州",
                "label": "黔东南苗族侗族自治州",
        
            },
            {
                "code": "522700",
                "value": "黔南布依族苗族自治州",
                "label": "黔南布依族苗族自治州",
   
            }
        ]
    },
    {
        "code": "530000",
        "value": "云南",
        "label": "云南",
        "children": [
            {
                "code": "530100",
                "value": "昆明",
                "label": "昆明",
   
            },
            {
                "code": "530300",
                "value": "曲靖",
                "label": "曲靖",

            },
            {
                "code": "530400",
                "value": "玉溪",
                "label": "玉溪",
 
            },
            {
                "code": "530500",
                "value": "保山",
                "label": "保山",
         
            },
            {
                "code": "530600",
                "value": "昭通",
                "label": "昭通",
  
            },
            {
                "code": "530700",
                "value": "丽江",
                "label": "丽江",
  
            },
            {
                "code": "530800",
                "value": "普洱",
                "label": "普洱",
     
            },
            {
                "code": "530900",
                "value": "临沧",
                "label": "临沧",
   
            },
            {
                "code": "532300",
                "value": "楚雄彝族自治州",
                "label": "楚雄彝族自治州",

            },
            {
                "code": "532500",
                "value": "红河哈尼族彝族自治州",
                "label": "红河哈尼族彝族自治州",
    
            },
            {
                "code": "532600",
                "value": "文山壮族苗族自治州",
                "label": "文山壮族苗族自治州",
 
            },
            {
                "code": "532800",
                "value": "西双版纳傣族自治州",
                "label": "西双版纳傣族自治州",
    
            },
            {
                "code": "532900",
                "value": "大理白族自治州",
                "label": "大理白族自治州",
            },
            {
                "code": "533100",
                "value": "德宏傣族景颇族自治州",
                "label": "德宏傣族景颇族自治州",
    
            },
            {
                "code": "533300",
                "value": "怒江傈僳族自治州",
                "label": "怒江傈僳族自治州",
    
            },
            {
                "code": "533400",
                "value": "迪庆藏族自治州",
                "label": "迪庆藏族自治州",
      
            }
        ]
    },
    {
        "code": "540000",
        "value": "西藏自治区",
        "label": "西藏自治区",
        "children": [
            {
                "code": "540100",
                "value": "拉萨",
                "label": "拉萨",
    
            },
            {
                "code": "540200",
                "value": "日喀则",
                "label": "日喀则",
      
            },
            {
                "code": "540300",
                "value": "昌都",
                "label": "昌都",
     
            },
            {
                "code": "540400",
                "value": "林芝",
                "label": "林芝",
       
            },
            {
                "code": "540500",
                "value": "山南",
                "label": "山南",
        
            },
            {
                "code": "540600",
                "value": "那曲",
                "label": "那曲",

            },
            {
                "code": "542500",
                "value": "阿里地区",
                "label": "阿里地区",
       
            }
        ]
    },
    {
        "code": "610000",
        "value": "陕西",
        "label": "陕西",
        "children": [
            {
                "code": "610100",
                "value": "西安",
                "label": "西安",
        
            },
            {
                "code": "610200",
                "value": "铜川",
                "label": "铜川",
  
            },
            {
                "code": "610300",
                "value": "宝鸡",
                "label": "宝鸡",
       
            },
            {
                "code": "610400",
                "value": "咸阳",
                "label": "咸阳",
          
            },
            {
                "code": "610500",
                "value": "渭南",
                "label": "渭南",
           
            },
            {
                "code": "610600",
                "value": "延安",
                "label": "延安",
         
            },
            {
                "code": "610700",
                "value": "汉中",
                "label": "汉中",
      
            },
            {
                "code": "610800",
                "value": "榆林",
                "label": "榆林",
        
            },
            {
                "code": "610900",
                "value": "安康",
                "label": "安康",
     
            },
            {
                "code": "611000",
                "value": "商洛",
                "label": "商洛",
       
            }
        ]
    },
    {
        "code": "620000",
        "value": "甘肃",
        "label": "甘肃",
        "children": [
            {
                "code": "620100",
                "value": "兰州",
                "label": "兰州",
  
            },
            {
                "code": "620200",
                "value": "嘉峪关",
                "label": "嘉峪关",

            },
            {
                "code": "620300",
                "value": "金昌",
                "label": "金昌",
  
            },
            {
                "code": "620400",
                "value": "白银",
                "label": "白银",

            },
            {
                "code": "620500",
                "value": "天水",
                "label": "天水",
       
            },
            {
                "code": "620600",
                "value": "武威",
                "label": "武威",
 
            },
            {
                "code": "620700",
                "value": "张掖",
                "label": "张掖",
    
            },
            {
                "code": "620800",
                "value": "平凉",
                "label": "平凉",
    
            },
            {
                "code": "620900",
                "value": "酒泉",
                "label": "酒泉",

            },
            {
                "code": "621000",
                "value": "庆阳",
                "label": "庆阳",
   
            },
            {
                "code": "621100",
                "value": "定西",
                "label": "定西",
  
            },
            {
                "code": "621200",
                "value": "陇南",
                "label": "陇南",
 
            },
            {
                "code": "622900",
                "value": "临夏回族自治州",
                "label": "临夏回族自治州",
    
            },
            {
                "code": "623000",
                "value": "甘南藏族自治州",
                "label": "甘南藏族自治州",
     
            }
        ]
    },
    {
        "code": "630000",
        "value": "青海",
        "label": "青海",
        "children": [
            {
                "code": "630100",
                "value": "西宁",
                "label": "西宁",
     
            },
            {
                "code": "630200",
                "value": "海东",
                "label": "海东",
     
            },
            {
                "code": "632200",
                "value": "海北藏族自治州",
                "label": "海北藏族自治州",
     
            },
            {
                "code": "632300",
                "value": "黄南藏族自治州",
                "label": "黄南藏族自治州",
        
            },
            {
                "code": "632500",
                "value": "海南藏族自治州",
                "label": "海南藏族自治州",
         
            },
            {
                "code": "632600",
                "value": "果洛藏族自治州",
                "label": "果洛藏族自治州",
   
            },
            {
                "code": "632700",
                "value": "玉树藏族自治州",
                "label": "玉树藏族自治州",
         
            },
            {
                "code": "632800",
                "value": "海西蒙古族藏族自治州",
                "label": "海西蒙古族藏族自治州",
          
            }
        ]
    },
    {
        "code": "640000",
        "value": "宁夏",
        "label": "宁夏",
        "children": [
            {
                "code": "640100",
                "value": "银川",
                "label": "银川",
         
            },
            {
                "code": "640200",
                "value": "石嘴山",
                "label": "石嘴山",
      
            },
            {
                "code": "640300",
                "value": "吴忠",
                "label": "吴忠",
 
            },
            {
                "code": "640400",
                "value": "固原",
                "label": "固原",
      
            },
            {
                "code": "640500",
                "value": "中卫",
                "label": "中卫",

            }
        ]
    },
    {
        "code": "650000",
        "value": "新疆",
        "label": "新疆",
        "children": [
            {
                "code": "650100",
                "value": "乌鲁木齐",
                "label": "乌鲁木齐",
      
            },
            {
                "code": "650200",
                "value": "克拉玛依",
                "label": "克拉玛依",
       
            },
            {
                "code": "650400",
                "value": "吐鲁番",
                "label": "吐鲁番",
       
            },
            {
                "code": "650500",
                "value": "哈密",
                "label": "哈密",
    
            },
            {
                "code": "652300",
                "value": "昌吉回族自治州",
                "label": "昌吉回族自治州",
       
            },
            {
                "code": "652700",
                "value": "博尔塔拉蒙古自治州",
                "label": "博尔塔拉蒙古自治州",
     
            },
            {
                "code": "652800",
                "value": "巴音郭楞蒙古自治州",
                "label": "巴音郭楞蒙古自治州",
           
            },
            {
                "code": "652900",
                "value": "阿克苏地区",
                "label": "阿克苏地区",
           
            },
            {
                "code": "653000",
                "value": "克孜勒苏柯尔克孜自治州",
                "label": "克孜勒苏柯尔克孜自治州",
         
            },
            {
                "code": "653100",
                "value": "喀什地区",
                "label": "喀什地区",
         
            },
            {
                "code": "653200",
                "value": "和田地区",
                "label": "和田地区",
        
            },
            {
                "code": "654000",
                "value": "伊犁哈萨克自治州",
                "label": "伊犁哈萨克自治州",
          
            },
            {
                "code": "654200",
                "value": "塔城地区",
                "label": "塔城地区",
 
            },
            {
                "code": "654300",
                "value": "阿勒泰地区",
                "label": "阿勒泰地区",
         
            },
            {
                "code": "659001",
                "value": "石河子",
                "label": "石河子",

            },
            {
                "code": "659002",
                "value": "阿拉尔",
                "label": "阿拉尔",
   
            },
            {
                "code": "659003",
                "value": "图木舒克",
                "label": "图木舒克",
   
            },
            {
                "code": "659004",
                "value": "五家渠",
                "label": "五家渠",
    
            },
            {
                "code": "659005",
                "value": "北屯",
                "label": "北屯",
   
            },
            {
                "code": "659006",
                "value": "铁门关",
                "label": "铁门关",
     
            },
            {
                "code": "659007",
                "value": "双河",
                "label": "双河",
  
            },
            {
                "code": "659008",
                "value": "可克达拉",
                "label": "可克达拉",

            },
            {
                "code": "659009",
                "value": "昆玉",
                "label": "昆玉",
  
            },
            {
                "code": "659010",
                "value": "胡杨河",
                "label": "胡杨河",
     
            },
            {
                "code": "659011",
                "value": "新星",
                "label": "新星",
      
            }
        ]
    },
    {
        "code": "710000",
        "value": "台湾",
        "label": "台湾",
        "children": []
    },
    {
        "code": "810000",
        "value": "香港特别行政区",
        "label": "香港特别行政区",
        "children": []
    },
    {
        "code": "820000",
        "value": "澳门特别行政区",
        "label": "澳门特别行政区",
        "children": []
    }
]

export default options