import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import index from '../views/index/index'
import urllist from '../views/urllist/urllist'
import blacklist from '../views/urllist/blacklist'

import nodelist from '../views/authority/nodelist'
import rolelist from '../views/authority/rolelist'

import account from '../views/account/account'
import person from '../views/account/person'
import member from '../views/account/member'

import system from '../views/system/system'
import flowlist from '../views/flow/flowlist'

const routes = [
  { path: '/', name: 'home', component: HomeView , redirect: {path: '/index' },
    children:[
      {path:"/index",name:"index", component: index ,meta:{title:"首页"}},
      {path:"/urllist",name:"urllist", component: urllist ,meta:{title:"路径列表"}},
      {path:"/blacklist",name:"blacklist", component: blacklist ,meta:{title:"公用屏蔽库"}},

      {path:"/nodelist",name:"nodelist", component: nodelist ,meta:{title:"节点列表"}},
      {path:"/rolelist",name:"rolelist", component: rolelist ,meta:{title:"角色列表"}},

      {path:"/account",name:"account", component: account ,meta:{title:"账号列表"}},
      {path:"/person",name:"person", component: person ,meta:{title:"账号信息"}},
      {path:"/member",name:"member", component: member ,meta:{title:"会员信息"}},

      {path:"/system",name:"system", component: system,meta:{title:"系统设置"} },
      {path:'flowlist',name:'flowlist',component:flowlist,meta:{title:"流量报表"}},
    ]
  },
  {path: '/login',name: 'login',component: login,meta:{title:"登录"}}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  const title = (to.meta.title ? to.meta.title : '') + '-URL路径管理平台'
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})

export default router
