<template>
    <div>
        <div class="index-top-searchlist">
            <a-radio-group v-model:value="kjdate" button-style="solid">
                <a-radio-button value="a">今天</a-radio-button>
                <a-radio-button value="b">昨天</a-radio-button>
                <a-radio-button value="c">最近7天</a-radio-button>
                <a-radio-button value="d">最近30天</a-radio-button>
            </a-radio-group>

            <div style="margin-left:20px">
                <a-range-picker v-model:value="value1" />
                <a-button type="primary" >查询</a-button>
            </div>
            
        </div>

        <a-row :gutter="16" style="margin-top:34px">
            <a-col class="gutter-row" :span="16">
                <div id="leftCharts" style="width:100%;height:390px;"></div>
            </a-col>
            <a-col class="gutter-row" :span="8">
                <div id="rightCharts" style="width:100%;height:390px;"></div>
            </a-col>
        </a-row>

        <a-row :gutter="16" >
            <a-col class="gutter-row" :span="24">
                <div id="bottomCharts" style="width:100%;height:346px;"></div>
            </a-col>
        </a-row>
        
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import * as echarts from 'echarts';
import { nextTick } from 'vue'
import moment from 'moment';
export default {
    setup(){
        let kjdate=ref("a")
        let dateSearch=ref()
        if(kjdate=="a"){
            dateSearch=moment(data()).unix()
        }

        // 柱状图
        let getbarcharts=function(){
            let Charts1 = echarts.init(document.getElementById('leftCharts'));
            Charts1.setOption({
                title: {
                    text: '地区分析图',
                },
                xAxis: {
                    type: 'category',
                    data: ['北京', '上海', '苏州', '重庆', '天津','石家庄','南京',"四川"]
                },
                yAxis: {
                    type: 'value'
                },
                grid: {top:'17%',left:"6%",right:"6%"},
                series: [
                    {
                    data: [120, 200, 150, 80, 70, ],
                    type: 'bar',
                    itemStyle: {
                    normal: {
                        //每根柱子颜色设置
                        color: function (params) {
                        let colorList = ["#709af3", "#64ddae", "#f6b23f", "#ea6a65","#749c51"
                        ];
                        return colorList[params.dataIndex];
                        }
                    }
                    },
                    }
                ]
            })
        }
        nextTick(()=>{
            getbarcharts()

        })
        
        // 饼状图
        let getpiecharts=function(){
            let Charts2 = echarts.init(document.getElementById('rightCharts'));
            Charts2.setOption({
            title: {
                text: '搜索引擎分析',
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            grid: {top:'30%',left:"6%",right:"6%"},
            series: [
                {
                type: 'pie',
                radius: ['32%', '62%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 1048, name: '百度' },
                    { value: 735, name: '360' },
                    { value: 580, name: '谷歌' },
                    { value: 484, name: '搜狗' },
                    { value: 300, name: '必应' }
                ]
                }
            ]
            })
        }

        nextTick(()=>{
            getpiecharts()

        })

        // 线形图

        let getlinecharts=function(){
            let Charts3 = echarts.init(document.getElementById('bottomCharts'));
            Charts3.setOption({
            title: {
                text: '流量趋势图',
            },
            grid: {top:'17%',left:"6%",right:"5%"},
            xAxis: {
                type: 'category',
                data: ['03-01', '03-02', '03-04', '03-05', '03-06', '03-07']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: 'line',
                smooth: true,
                itemStyle:{
                    normal: {
                        lineStyle: {
                            width:5
                        }
                    }
                },
                }
            ]
            })
        }

        nextTick(()=>{
            getlinecharts()

        })

        return{
            kjdate,getbarcharts,getpiecharts
        }
    }
}
</script>